
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Homecomponent from './components/Home';
import About from './components/pages/About';
import Product from './components/pages/Product';
import Home from './components/pages/Home';
import Contact from './components/pages/Contact';
import Services from './components/pages/Services';

function App() {
  return (
    <div >
      <Homecomponent />
      <Routes>
      <Route path="/" element={<About />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/product" element={<Product />} />
      </Routes>
    </div>
  );
}

export default App;
