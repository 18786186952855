import React from "react";
import "./Text.css";
function About () {
    return (<div className="about-container">
        <h2>About Us</h2>
        <p>Gaghan’s Life Sciences is a leading pharmaceutical company that has been at the forefront of producing high-quality chemicals for various industries. Established in 2023, we have built a reputation for excellence, innovation, and reliability in delivering chemical solutions that meet the diverse needs of our global clientele.<br></br>
We collaborate closely with our clients to understand their unique needs and challenges, tailoring our services to support their specific goals. This collaborative approach fosters innovation and ensures the success of each project.<br></br>
We are committed to staying at the forefront of technological advancements in the pharmaceutical industry. We leverage cutting-edge technologies, and innovative methodologies to enhance the efficiency and accuracy of our services. Our investment in technology ensures that our clients benefit from the latest advancements in research and development.<br></br>
At Gaghan’s Life Sciences, we prioritize building strong and enduring partnerships with our clients. Our client-centric approach is rooted in transparency, communication, and a commitment to exceeding expectations. We view our clients as collaborators, working together to achieve shared success in advancing healthcare innovation.</p>
<h2>Mission</h2>
<p>Our mission is to be a trusted partner in the chemical industry by providing innovative, sustainable, and high-performance solutions to our customers. We are committed to advancing technology, ensuring environmental responsibility, and contributing to the success of our clients through the quality of our products and services.</p>
<h2>Vision</h2>
<p>To be a competitive leader in the chemical sector locally and globally by offering top-notch products, services, and values.</p>
    </div>
    );
}
export default About;